<template>
  <div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  layout: "app",
  setup() {
    const router = useRouter();
    router.push("/subject/");
  }
}
</script>
